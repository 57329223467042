import cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import 'subscriptions-transport-ws';

export interface AsarenAPIClientConfig {
  apiUrl: string;
  subscriptionUrl: string;
  cryptoGraphqlKey: string;
  cryptoGraphqlSecret: string;
  cryptoGraphqlAlgorithm: string;
}

export class AsarenAPIClient {
  /* eslint-disable no-useless-constructor */
  constructor(private config: AsarenAPIClientConfig) {}

  getToken(): string {
    const token = window.localStorage['auth._token.local'];
    return cookie.get('access-token') || token?.replace('Bearer ', '');
  }

  createApolloClient(): ApolloClient<any> {
    // HTTP connection to the API
    const httpLink = createUploadLink({
      uri: this.config.apiUrl,
      fetch: (input: RequestInfo, init: any) => {
        // ssr problem, window is not defined in promise below
        const globalWindow = window;
        const token = this.getToken();

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const url = this.config.apiUrl || input;
          // set authorization header
          if (init.headers && init.headers.set) {
            init.headers.set('Authorization', `Bearer ${token}`);
          } else {
            init.headers = init.headers || ({} as any);
            init.headers.Authorization = `Bearer ${token}`;
          }

          let response = null;
          let body = null;
          try {
            response = await fetch(url, init);
            body = await response.json();
          } catch (error) {
            reject(error);
          }

          const isEncrypted = (
            response.headers.get('Content-Type') || ''
          ).includes('base64');

          if (isEncrypted) {
            try {
              const decrypted = await this.decrypt(
                body.data,
                globalWindow.crypto
              );
              Object.assign(body, {
                data: JSON.parse(decrypted)
              });
            } catch (error) {
              reject(error);
            }
          }

          response.text = () =>
            new Promise((resolve) => {
              resolve(JSON.stringify(body));
            });

          response.json = () =>
            new Promise((resolve) => {
              resolve(body);
            });

          resolve(response);
        });
      }
    });

    // Create the subscription websocket link
    const wsLink = process.client
      ? new WebSocketLink({
          uri: this.config.subscriptionUrl,
          options: {
            reconnect: true,
            connectionParams: () => ({
              token: this.getToken()
            })
          }
        })
      : null;

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = process.server
      ? httpLink
      : split(
          // split based on operation type
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          wsLink,
          httpLink
        );

    // Cache implementation
    const cache = new InMemoryCache();

    // Create apollo client
    return new ApolloClient({
      link,
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only'
        }
      },
      connectToDevTools: true
    });
  }

  async decrypt(data: string, crypto: Crypto = window.crypto): Promise<any> {
    try {
      const algorithm = this.config.cryptoGraphqlAlgorithm;

      const iv = new TextEncoder().encode(this.config.cryptoGraphqlSecret);
      const key = await crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(this.config.cryptoGraphqlKey),
        algorithm,
        true,
        ['decrypt']
      );

      const buffer = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));

      const decrypt = await crypto.subtle.decrypt(
        {
          name: algorithm,
          iv,
          tagLength: 128
        },
        key,
        buffer
      );

      return new TextDecoder().decode(decrypt);
    } catch (error) {
      throw new Error('Failed to decrypt graphql response data');
    }
  }
}
